import { funk as theme } from "@theme-ui/presets";

export default {
  ...theme,
  colors: {
    ...theme.colors,
    text: "#5F8575",
    background: "#fff"
  }
};
