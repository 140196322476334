import React from 'react'
import {navigate} from 'gatsby'
import {AuthProvider} from "react-use-auth";
import {ApolloProvider} from 'react-apollo-hooks'
import config from "./site-config"
import {client} from "./src/apollo";

export const wrapRootElement = ({element}) =>
  <ApolloProvider client={client}>
    <AuthProvider navigate={navigate} {...config.auth0}>
      {element}
    </AuthProvider>
  </ApolloProvider>
