module.exports = {
  auth0: {
    auth0_domain: "hempmarket.eu.auth0.com",
    auth0_client_id: "A0aNrheHeMfZC2j92B2aAAA5mb9y7fmk"
  },

  hempmarket_api: {
    url: 'https://4nyhu3xo39.execute-api.us-east-1.amazonaws.com/dev/graphql'
  }
}
