// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-0-callback-js": () => import("./../src/pages/auth0_callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sklepy-konopne-cbd-js": () => import("./../src/pages/sklepy-konopne-cbd.js" /* webpackChunkName: "component---src-pages-sklepy-konopne-cbd-js" */)
}

